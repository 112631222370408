.ant-layout {
  background: #fff;
}

.txt-blue {
  color: blue;
}

.txt-payment {
  font-size: 1.5rem;
  color: red;
  font-weight: bold;
}

.btn-confirm {
  width: fit-content;
  min-width: 250px;
  height: 50px;
  border-radius: 12px;
  border: solid 1px green;
  background: green;
  color: #fff;
  font-weight: bold;

  &:hover {
    border: solid 1px green !important;
    background: green !important;
    color: #fff !important;
    opacity: 0.8;
  }

  &:disabled {
    color: green;
    opacity: 0.5;
  }
}

.btn-cancel {
  width: fit-content;
  min-width: 250px;
  height: 50px;
  border-radius: 12px;
  border: solid 1px #0000000D;
  background: #0000000D;
  color: #000000B2;
  font-weight: bold;

  &:hover {
    border: solid 1px #0000000D !important;
    background: #0000000D !important;
    color: #000000B2 !important;
    opacity: 0.8;
  }

}


.btn-close {
  width: fit-content;
  min-width: 250px;
  height: 50px;
  border-radius: 12px;
  border: solid 1px red;
  background: #fff;
  color: red;
  font-weight: bold;

  &:hover {
    border: solid 1px red !important;
    background: #fff !important;
    color: red !important;
    opacity: 0.8;
  }

  &:active {
    border: solid 1px red !important;
    background: #fff !important;
    color: red !important;
    opacity: 0.8;
  }

}

.customer-error {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: red;
  min-height: 100vh;
}